import React from "react"
import SEO from '../components/seo'
import { Link } from "gatsby"

import Layout from '../layouts/layout'
import Soundcloud from '../components/soundcloud'


export default () => (


  <Layout>

    <SEO title="404: Not found" />

	<main id="wrapper" class="seigen">

		<h1>ページが見つかりません</h1>

		<h3>まあ曲でも聞きなよ</h3>

		<Soundcloud/>

		<Link to="/">トップページへ</Link>

	</main>


  </Layout>
)
